.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: rgb(143, 29, 29);
}

.database-component>div>.MuiToolbar-root {
    display: none;
}

table>thead>tr>th {
    font-weight: bold !important;
}